import * as React from 'react';
import { Component } from "react";
import styled from 'styled-components';
import Layout from "../components/layout";
import { SEO } from '../components/seo';
import { TwitterButton } from '../components/atoms/links/TwitterButton';
import { YoutubeChannelButton } from '../components/atoms/links/YoutubeChannelButton';

const LinkButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const LinkButton = styled.div`
  width: 48px;
  height: 48px;

  margin-right: 16px;
`;

export default class IndexPage extends Component{
  public render() : JSX.Element{
    return(
      <Layout>
        <SEO title="Home" />
        <p>Manicreatorはみんなを楽しませるコンテンツを製作するチームです。</p>
        <LinkButtonContainer>
          <LinkButton>
            <YoutubeChannelButton />
          </LinkButton>
          <LinkButton>
            <TwitterButton />
          </LinkButton>
        </LinkButtonContainer>
      </Layout>
    );
  }
}