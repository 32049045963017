import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { BlandColor, AppColor } from '../../../constants/Color';
import { Twitter } from "@styled-icons/fa-brands/Twitter";

const LinkButton = styled.a<{}>`
  width: 100%;
  height: 100%;

  background-color: white;

  border-style: solid;
  border-color: ${AppColor.PRIMARY};
  border-width: 1px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  &:hover{
    background-color: ${AppColor.PRIMARY_TRANS};
  }
`;

const Icon = styled(Twitter)<{}>`
  width: 50%;
  height: 50%;

  color: ${BlandColor.TWITTER};
`;

interface Property{
  accountName?: string;
}

export class TwitterButton extends PureComponent<Property>{

  private getLink(accountName: string) : string{
    return `https://twitter.com/${accountName}`;
  }

  public render() : JSX.Element{
    let acutalAccountName: string;
    if(this.props.accountName === undefined){
      const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                twitter
              }
            }
          }
        `
      );
      
      acutalAccountName = site.siteMetadata.twitter;
    }
    else{
      acutalAccountName = this.props.accountName;
    }

    return(
      <LinkButton href={this.getLink(acutalAccountName)}>
        <Icon />
      </LinkButton>
    );
  }
}