import * as React from 'react';
import { PureComponent } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from "gatsby";
import { BlandColor, AppColor } from '../../../constants/Color';
import { Youtube } from "@styled-icons/fa-brands/Youtube";

const LinkButton = styled.a<{}>`
  width: 100%;
  height: 100%;

  background-color: white;

  border-style: solid;
  border-color: ${AppColor.PRIMARY};
  border-width: 1px;
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;

  &:hover{
    background-color: ${AppColor.PRIMARY_TRANS};
  }
`;

const Icon = styled(Youtube)<{}>`
  width: 50%;
  height: 50%;

  color: ${BlandColor.YOUTUBE};
`;

export class YoutubeChannelButton extends PureComponent{

  public render() : JSX.Element{
    const { site } = useStaticQuery(
      graphql`
        query {
          site {
            siteMetadata {
              youtube
            }
          }
        }
      `
    );

    return(
      <LinkButton href={site.siteMetadata.youtube}>
        <Icon />
      </LinkButton>
    );
  }
}